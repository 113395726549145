import "core-js/modules/es.array.push.js";
import { defineComponent, reactive, toRefs, inject, watch, defineAsyncComponent, computed, unref } from 'vue';
import { ElSpace, ElSwitch, ElSlider, ElSelect, ElOption, ElProgress } from 'element-plus';
import { isNullOrUnDef, isEmpty } from '@/utils/is';
import { bdEncrypt } from '@/utils/bdGPS';
import { useDebounce } from '@/hooks/core/useDebounce';
import sleep from '@/utils/sleep';
import BSJMap from '@/components/BSJMap';
var PlayStatus;
(function (PlayStatus) {
  PlayStatus[PlayStatus["Init"] = 0] = "Init";
  PlayStatus[PlayStatus["End"] = 1] = "End";
  PlayStatus[PlayStatus["Playing"] = 2] = "Playing";
  PlayStatus[PlayStatus["Pause"] = 3] = "Pause";
})(PlayStatus || (PlayStatus = {}));
export default defineComponent({
  name: 'TrajectoryMap',
  components: {
    ElSpace,
    ElSwitch,
    ElSlider,
    ElSelect,
    ElOption,
    ElProgress,
    BSJMap,
    InfoContent: defineAsyncComponent(() => import('./InfoContent.vue')),
    TrajectoryMapSearch: defineAsyncComponent(() => import('./TrajectoryMapSearch.vue')),
    PlayProgress: defineAsyncComponent(() => import('./PlayProgress.vue'))
  },
  emits: ['search', 'update:showFence'],
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    showFence: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const refRoot = inject('RootProvide');
    const refData = reactive({
      refMap: null,
      showTrack: true,
      playProgress: 0,
      playSpeed: 800,
      playStatus: PlayStatus.Init,
      lon: null,
      lat: null
    });
    // 点标记集合
    const mapMarkers = new Map();
    // 轨迹线
    let mapPolyline = {
      polyline: null,
      // 开始位置点
      startMarker: null,
      // 结束位置点
      endMarker: null
    };
    // 播放轨迹线
    let moveObj = {
      polyline: null,
      // 播放当前位置点
      marker: null
    };
    // 围栏多边形实例
    let fencePolygon = null;
    // 当前位置点
    const currentPos = computed(() => refRoot.posList.find(p => p.posId === refRoot.currentPosId));
    const infoContent = computed(() => {
      const cardInfo = refRoot.cardInfo;
      const pos = unref(currentPos);
      if (isNullOrUnDef(pos)) return null;
      return {
        avatarImg: require('@/assets/images/avatar.png'),
        name: cardInfo.objectname,
        gender: ['熄火', '启动', ''][cardInfo.acc],
        // online: currentStudent.onlineStatus,
        posTypeStr: currentPos.value.posTypeStr,
        mdtid: cardInfo.mdtid,
        workMode: cardInfo.workMode,
        speed: currentPos.value.speed,
        mdtstatus: currentPos.value.mdtstatus,
        disturbStatus: cardInfo.disturbStatus,
        posTime: pos.devTimeStr,
        point: {
          lon: pos.lon,
          lat: pos.lat
        }
      };
    });
    function getMap() {
      return [refData.refMap.getMap(), refData.refMap];
    }
    // 推送新的聚合点
    async function pushMarkers(posList) {
      var _refRoot$cardInfo;
      const [map, refMap] = getMap();
      if (isNullOrUnDef(map)) return;
      if (posList.length === 0) return;
      if (isNullOrUnDef(refRoot.cardInfo)) return;
      const acc = (_refRoot$cardInfo = refRoot.cardInfo) === null || _refRoot$cardInfo === void 0 ? void 0 : _refRoot$cardInfo.acc;
      posList.forEach(pos => {
        if (mapMarkers.has(pos.posId)) return;
        const point = bdEncrypt([pos.lon, pos.lat]);
        const marker = refMap.addPosMarker([point.lon, point.lat], {
          acc
        });
        const params = {
          point: [point.lon, point.lat],
          acc,
          info: {
            ...pos,
            acc
          }
        };
        onMarkerClick({
          ...pos,
          acc
        });
        mapMarkers.set(pos.posId, params);
      });
      refMap.addClusterer([...mapMarkers.values()]);
    }
    function clearMarkers() {
      const [map, refMap] = getMap();
      refMap.clearClusterer();
      mapMarkers.clear();
    }
    function onMarkerClick(pos) {
      const [map, refMap] = getMap();
      // map.handleAddEvent(marker, 'click', () => {
      if (refRoot.currentPosId !== pos.posId) {
        // 切换标记点, 并打开窗口
        refRoot.currentPosId = pos.posId;
        return;
      }
      // 标记点已经打开, 则关闭
      if (refMap.isOpenWindow()) {
        return closeInfoWindow();
      }
      openInfoWindow();
      // });
    }
    // 打开窗口
    function openInfoWindow() {
      const [map, refMap] = getMap();
      const current = unref(currentPos);
      if (isNullOrUnDef(current)) {
        return;
      }
      const point = bdEncrypt([current.lon, current.lat]);
      refMap.openInfoWindow({
        lon: point.lon,
        lat: point.lat
      });
    }
    // 关闭窗口
    function closeInfoWindow() {
      const [map, refMap] = getMap();
      refMap.closeInfoWindow();
    }
    // 绘制轨迹线
    async function drawLine() {
      const [map, refMap] = getMap();
      if (isNullOrUnDef(map)) return;
      if (refRoot.posList.length === 0) return;
      const points = refRoot.posList.map(pos => {
        const point = bdEncrypt([pos.lon, pos.lat]);
        return [point.lon, point.lat];
      });
      const startPoint = points[0];
      const endPoint = points[points.length - 1];
      mapPolyline.polyline = map.setPolyline(points, {
        strokeColor: '#67C23A',
        strokeWeight: 6,
        strokeOpacity: 1
      });
      mapPolyline.startMarker = refMap.addStartMarker(startPoint);
      mapPolyline.endMarker = refMap.addEndMarker(endPoint);
      mapPolyline.startMarker.setTop(false);
      mapPolyline.endMarker.setTop(false);
      map.setMap(mapPolyline.startMarker);
      map.setMap(mapPolyline.endMarker);
      onMarkerClick(refRoot.posList[0]);
      onMarkerClick(refRoot.posList[refRoot.posList.length - 1]);
      refRoot.currentPosId = refRoot.posList[0].posId;
    }
    // 移除轨迹线
    async function removeLine() {
      const [map] = getMap();
      if (isNullOrUnDef(map)) return;
      if (!isNullOrUnDef(mapPolyline.polyline)) {
        map.removeCover(mapPolyline.polyline);
        mapPolyline.polyline = null;
      }
      if (!isNullOrUnDef(mapPolyline.startMarker)) {
        map.removeCover(mapPolyline.startMarker);
        mapPolyline.startMarker = null;
      }
      if (!isNullOrUnDef(mapPolyline.endMarker)) {
        map.removeCover(mapPolyline.endMarker);
        mapPolyline.endMarker = null;
      }
    }
    // 添加轨迹线的 点标记
    function addPolylineMarker(pos) {
      var _refRoot$cardInfo2;
      const [map, refMap] = getMap();
      if (isNullOrUnDef(map)) return;
      const acc = (_refRoot$cardInfo2 = refRoot.cardInfo) === null || _refRoot$cardInfo2 === void 0 ? void 0 : _refRoot$cardInfo2.acc;
      let point1 = bdEncrypt([pos.lon, pos.lat]);
      let point = [point1.lon, point1.lat];
      if (!moveObj.marker) {
        moveObj.marker = refMap.addPosMarker(point, {
          acc
        });
        map.setMap(moveObj.marker);
      } else {
        // moveObj.marker.show();
        moveObj.marker.setPosition(map.setPoint(point));
      }
    }
    // 轨迹播放画线
    function movePoyline(pos) {
      const [map, refMap] = getMap();
      if (isNullOrUnDef(map)) return;
      let point1 = bdEncrypt([pos.lon, pos.lat]);
      let point = [point1.lon, point1.lat];
      if (!moveObj.polyline) {
        moveObj.polyline = map.setPolyline([point], {
          strokeColor: '#409EFF',
          strokeWeight: 6,
          strokeOpacity: 1
        });
      } else {
        const path = moveObj.polyline.getPath();
        path.push(map.setPoint(point));
        moveObj.polyline.setPath(path);
      }
    }
    // 开始播放
    async function startPlay() {
      clearMarkers();
      const list = [...refRoot.posList];
      // 判断能否播放
      function canPlay() {
        return !isEmpty(list) && (refData.playStatus === PlayStatus.Playing || refData.playStatus === PlayStatus.Pause);
      }
      while (canPlay()) {
        if (refData.playStatus === PlayStatus.Pause) {
          // 暂停状态
          await sleep(1000);
          continue;
        }
        const pos = list.shift();
        const total = refRoot.posList.length;
        if (refData.lon == null) {
          refData.lon = pos.lon;
          refData.lat = pos.lat;
        } else {
          if (refData.lon.toFixed(5) == pos.lon.toFixed(5) && refData.lat.toFixed(5) == pos.lat.toFixed(5)) {
            refRoot.currentPosId = pos.posId;
            // 进度
            refData.playProgress = Math.floor((1 - list.length / total) * 100);
            continue;
          }
          refData.lon = pos.lon;
          refData.lat = pos.lat;
        }
        if (refData.showTrack) {
          movePoyline(pos);
          addPolylineMarker(pos);
        } else {
          // 添加当前进度的点标记
          pushMarkers([pos]);
          // TODO: 也可通过监听currentPos实现
        }
        refRoot.currentPosId = pos.posId;
        // 进度
        refData.playProgress = Math.floor((1 - list.length / total) * 100);
        await sleep(refData.playSpeed);
      }
      refData.playStatus = PlayStatus.End;
    }
    // 停止播放轨迹
    function stopPlay() {
      refData.playStatus = PlayStatus.End;
      refData.playProgress = 0;
      const [map] = getMap();
      if (isNullOrUnDef(map)) return;
      if (!isNullOrUnDef(moveObj.polyline)) {
        map.removeCover(moveObj.polyline);
        moveObj.polyline = null;
      }
      if (!isNullOrUnDef(moveObj.marker)) {
        map.removeCover(moveObj.marker);
        moveObj.marker = null;
      }
    }
    function onPlay() {
      if (refData.playStatus === PlayStatus.Init || refData.playStatus === PlayStatus.End) {
        if (refData.playStatus === PlayStatus.End) {
          stopPlay();
        }
        if (refRoot.posList.length > 0 && refRoot.cardInfo) {
          // 开始播放
          refData.playStatus = PlayStatus.Playing;
          startPlay();
        }
        return;
      }
      if (refData.playStatus === PlayStatus.Playing) {
        // 暂停播放
        refData.playStatus = PlayStatus.Pause;
        return;
      }
      if (refData.playStatus === PlayStatus.Pause) {
        // 继续播放
        refData.playStatus = PlayStatus.Playing;
        return;
      }
    }
    /** 通过已有经纬度 绘制多边形 围栏 */
    function renderPolygon(path) {
      const [map] = getMap();
      if (isNullOrUnDef(map)) return;
      if (!fencePolygon) {
        fencePolygon = map.setPolygon([], {
          strokeColor: '#67C23A',
          strokeWeight: 3,
          strokeOpacity: 1
        });
        fencePolygon.hide();
      }
      fencePolygon.hide();
      if (isNullOrUnDef(path) || path.length === 0) return;
      const newPath = path.map(({
        lon,
        lat
      }) => {
        const point = bdEncrypt({
          lat,
          lon
        });
        return [point.lon, point.lat];
      });
      fencePolygon.setPath(newPath.map(point => map.setPoint(point)));
      fencePolygon.show();
      map.setCenter(newPath[0]);
    }
    // 状态 位置变化
    async function posWatch() {
      if (refData.showTrack) {
        drawLine();
      } else {
        pushMarkers(refRoot.posList);
      }
    }
    const [debouncePosWatch] = useDebounce(posWatch, 100);
    // 数据源变化
    watch(() => refRoot.posList, () => {
      clearMarkers();
      removeLine();
      stopPlay();
      if (refRoot.posList.length > 0) {
        debouncePosWatch();
      }
    });
    // 轨迹切换
    watch(() => refData.showTrack, () => {
      clearMarkers();
      removeLine();
      stopPlay();
      if (refRoot.posList.length > 0) {
        debouncePosWatch();
      }
    });
    // 当前位置变化
    watch(currentPos, pos => {
      if (isNullOrUnDef(pos)) {
        // 关闭窗体
        closeInfoWindow();
        return;
      }
      openInfoWindow();
      // 轨迹线模式
      if (refData.showTrack) {
        addPolylineMarker(pos);
        return;
      }
      // 聚合点模式下, 正在播放点
      if (refData.playStatus === PlayStatus.Playing || refData.playStatus === PlayStatus.Pause) {
        // 地图上已经有这个点, 则不添加
        if (mapMarkers.has(pos.posId)) return;
        pushMarkers([pos]);
      }
    });
    return {
      ...toRefs(refData),
      renderPolygon,
      infoContent,
      onPlay,
      onMarkerClick
    };
  }
});